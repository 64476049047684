import axios from "axios";
import '../config'

let apiInstance = {
    baseURL: /* global.config.API_URL || */ /* 'https://influencersnode.ahsan.cloud/', */ 'https://services.socialtako.com/', /* 'http://192.168.1.14:5000/', */
    timeout: 100000,
    headers: { Accept: "application/json" },
}

axios.defaults.validateStatus = status => status >= 200 && status <= 700;
const api = axios.create(apiInstance);

// setting token for every call
api.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
)


export default api;