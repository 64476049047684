

module.exports = global.config = {

    // development
    // APP_URL: 'http://192.168.1.16:3000/',
    // API_URL: 'http://192.168.1.14:5000/',
    // SECRET_KEY: "be4671fca118a2eac9db6b81e89d078bd1a259c6f8d20ed291946235a12e46ad0d0046059e434d2ab42dbb032aec02f153bfa9e65644ad75691a2653a2ad7602",

    // staging
    APP_URL: 'https://influencers.ahsan.cloud/',
    API_URL: 'https://services.socialtako.com/',
    SECRET_KEY: "be4671fca118a2eac9db6b81e89d078bd1a259c6f8d20ed291946235a12e46ad0d0046059e434d2ab42dbb032aec02f153bfa9e65644ad75691a2653a2ad7602",


    // production
    // APP_URL: '--appUrl--',
    // API_URL: '--apiUrl--'
}